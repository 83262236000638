.default-hero-item .link {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.default-hero-item .link .icon {
  font-size: 1.5rem;
  color: #FFFFFF;
}
.default-hero-item .link a {
  text-decoration: underline;
  color: #FFFFFF;
  margin-top: 0;
}

.default-hero-item-content-mobile {
  padding: 1rem;
  background-color: #f0f0f0;
}
.default-hero-item-content-mobile h1 {
  font-size: 1.25rem;
  margin: 0;
}
.default-hero-item-content-mobile h4 {
  font-size: 1rem;
}
.default-hero-item-content-mobile .link a {
  display: inline-block;
}
.default-hero-item-content-mobile .link.default {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.default-hero-item-content-mobile .link.default .icon {
  font-size: 1.5rem;
  color: #013e7d;
}
.default-hero-item-content-mobile .link.default a {
  text-decoration: underline;
  color: #013e7d;
  margin-top: 0;
}